import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidatorsService } from '../../../../services/validators.service';
import { insurances } from '../../../../models/insurance/insurance-data.store';
import { brokers } from '../../../../models/broker/broker-data.store';
import { InvisibleReCaptchaComponent } from "ngx-captcha";
import { UserDataService } from "../../../../services/user-data.service";
import { AppState } from "../../../../app.reducer";
import { Store } from "@ngrx/store";
import { UserDataModel } from "../../../../store/user-data.model";
import { SetUserDataAction } from "../../../../store/user-data.actions";
import { filter,  Subscription } from "rxjs";

declare var hljs: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {

  public insurances = insurances;
  public brokers = brokers;
  public currentStep = 1;
  public data: any;
  public newCompany: boolean = false;
  public userDataForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, Validators.pattern(this.vs.onlyLetters)]],
    lastname1: ['', [Validators.required, Validators.pattern(this.vs.onlyLetters)]],
    lastname2: ['', Validators.pattern(this.vs.onlyLetters)],
    email: ['', [Validators.required, Validators.pattern(this.vs.emailPattern)]],
    phone1: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(15), Validators.pattern(this.vs.mobilePhonePattern)]],
    phone2: ['', [Validators.minLength(9), Validators.maxLength(15), Validators.pattern(this.vs.phonePattern)]],
    currentCompany: [this.brokers[0].value, [Validators.required, Validators.minLength(3)]],
    coverage: [this.insurances[0].value, [Validators.required]],
    checkPrivacy: [false, Validators.requiredTrue],
    checkCalling: [false, Validators.requiredTrue],
    recaptcha: ['', Validators.required]
  });
  public readonly siteKey = '6LcnfDcnAAAAABZus3WBtE7eewvZbZH6ZKriQ90F';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;
  public badge: 'bottomright' | 'bottomleft' | 'inline' = 'inline';
  // @ts-ignore
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';
  public userData: any = {};
  // @ts-ignore
  private userDataObject: UserDataModel;
  private subscription: Subscription = new Subscription();

  // @ts-ignore
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  // @ts-ignore
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  constructor(private formBuilder: FormBuilder,
              private vs: ValidatorsService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private store: Store<AppState>,
              private userDataService: UserDataService) {
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('insuadvisor_token') === null || sessionStorage.getItem('insuadvisor_token') === 'undefined') {
      this.router.navigate(['login']);
    } else {
      this.userDataService.loadData();
      this.subscription = this.store.select('user_data_state')
          .pipe(filter(state => state.userData != null))
          .subscribe(user_data_state => {
            this.userDataObject =  new UserDataModel({
              name: user_data_state.userData.name,
              lastname1: user_data_state.userData.lastname1,
              lastname2: user_data_state.userData.lastname2,
              email: user_data_state.userData.email,
              phone1: user_data_state.userData.phone1,
              phone2: user_data_state.userData.phone2,
              currentCompany: user_data_state.userData.currentCompany,
              coverage: user_data_state.userData.coverage
            });
            this.userData = this.userDataObject;
            // @ts-ignore
            this.userDataForm.get('name').setValue(this.userData.name);
            // @ts-ignore
            this.userDataForm.get('lastname1').setValue(this.userData.lastname1);
            // @ts-ignore
            this.userDataForm.get('lastname2').setValue(this.userData.lastname2);
            // @ts-ignore
            this.userDataForm.get('email').setValue(this.userData.email);
            // @ts-ignore
            this.userDataForm.get('phone1').setValue(this.userData.phone1);
            // @ts-ignore
            this.userDataForm.get('phone2').setValue(this.userData.phone2);
            // @ts-ignore
            this.userDataForm.get('currentCompany').setValue(this.userData.currentCompany);
            // @ts-ignore
            this.userDataForm.get('coverage').setValue(this.userData.coverage);
          });
    }
  }

  ngAfterViewInit(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
    this.highlight();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  public get form() {
    return this.userDataForm.controls;
  }

  public isFieldValid(field: string, $event: any) {
    this.showFieldErrorFeedback(field, $event);
    return this.userDataForm.controls[field].errors && this.userDataForm.controls[field].touched;
  }

  public showFieldErrorFeedback(field: string, $event: any) {
    let msg = '';
    if ($event.errors) {
      if ($event.errors['required']) {
        msg = 'required';
        return msg;
      } else {
        msg = 'pattern';
        return msg;
      }
    } else {
      return;
    }
  }

  public saveFormData() {
    this.data = this.userDataForm.value;
    delete this.data.checkPrivacy;
    delete this.data.checkCalling;
    delete this.data.recaptcha;
    const userDataObject = new UserDataModel({
      name: this.data.name,
      lastname1: this.data.lastname1,
      lastname2: this.data.lastname2,
      email: this.data.email,
      phone1: this.data.phone1,
      phone2: this.data.phone2,
      currentCompany: this.data.currentCompany,
      coverage: this.data.coverage
    });
    this.store.dispatch(new SetUserDataAction(userDataObject));
    this.userDataService.persistData(userDataObject);
    this.router.navigate(['/confirm']);
  }

}
