<div *ngIf="loading" id="overlay">
  <div class="mt-5 mb-5">
    <div class="text-center">
      <div class="prelogin-icon">
        <img ngSrc="../../../../assets/images/padlock.gif" alt="Padlock" height="50" width="50">
      </div>
      <div>
        <h4 class="mt-1">Comprobando validez de enlace<span class="dot1">.</span><span class="dot2">.</span><span class="dot3">.</span></h4>
      </div>
    </div>
  </div>
</div>
