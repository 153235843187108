<div class="mt-2 mb-4 animated fadeIn">
  <h4 class="mb-3">Ley de cookies</h4>
  <p>Esta política de cookies fue actualizada por última vez el 31 de julio de 2023. y se
    aplica a los ciudadanos y residentes legales permanentes del Espacio Económico
    Europeo y Suiza.</p>
  <div class="accordion mt-4" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          Introducción
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>Nuestra web, <a href="https://insuadvisor.com/">InsuAdvisor</a> (en adelante: «la web») utiliza cookies y otras
            tecnologías relacionadas (para mayor comodidad, todas las tecnologías se denominan
            «cookies»). Las cookies también son colocadas por terceros a los que hemos
            contratado. En el siguiente documento te informamos sobre el uso de cookies en
            nuestra web.</p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          ¿Qué son las cookies?
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>
            Una cookie es un pequeño archivo que se envía junto con las páginas de esta web y
            que tu navegador almacena en el disco duro de su ordenador u otro dispositivo. La
            información almacenada puede ser devuelta a nuestros servidores o a los servidores
            de terceros apropiados durante una visita posterior.
          </p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          ¿Qué son los scripts?
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>Un script es un fragmento de código de programa que se utiliza para hacer que nuestra
            web funcione correctamente y de forma interactiva. Este código se ejecuta en nuestro
            servidor o en tu dispositivo.</p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          ¿Qué es una baliza web?
        </button>
      </h2>
      <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>Una baliza web (o una etiqueta de píxel) es una pequeña e invisible pieza de texto o
            imagen en una web que se utiliza para monitorear el tráfico en una web. Para ello, se
            almacenan varios datos sobre usted mediante estas balizas web.</p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
          Cookies
        </button>
      </h2>
      <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <ul>
            <li>
              <p>5.1 Cookies técnicas o funcionales</p>
              <p>Algunas cookies aseguran que ciertas partes de la web funcionen correctamente y que
                tus preferencias de usuario sigan recordándose. Al colocar cookies funcionales, te
                facilitamos la visita a nuestra web. De esta manera, no necesitas introducir
                repetidamente la misma información cuando visitas nuestra web y, por ejemplo, los
                artículos permanecen en tu cesta de la compra hasta que hayas pagado. Podemos
                colocar estas cookies sin tu consentimiento.</p>
            </li>
            <li>
              <p>5.2 Cookies de estadísticas</p>
              <p>Utilizamos cookies estadísticas para optimizar la experiencia de la web para nuestros
                usuarios. Con estas cookies estadísticas obtenemos información sobre el uso de
                nuestra web. Te pedimos tu permiso para colocar cookies de estadísticas.</p>
            </li>
            <li>
              <p>5.3 Cookies de marketing/seguimiento</p>
              <p>Las cookies de marketing/seguimiento son cookies, o cualquier otra forma de
                almacenamiento local, usadas para crear perfiles de usuario para mostrar publicidad o
                para hacer el seguimiento del usuario en esta web o en varias webs con fines de
                marketing similares.</p>
            </li>
            <li>
              <p>5.4 Redes sociales</p>
              <p>En nuestra web hemos incluido contenido para promover páginas web (p.ej.: «Me
                gusta», «Pinear») o compartir (p.ej.: «tuitear») en redes sociales. Este contenido está
                incrustado con código derivado de terceros y guarda cookies. Este contenido podría
                procesar cierta información para anuncios personalizados.</p>
              <p>Por favor lea la política de privacidad de estas redes sociales (que puede cambiar
                frecuentemente) para saber que hacen con sus datos (personales) que procesan
                usando estas cookies. Los datos que reciben son anonimizados lo máximo posible.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
