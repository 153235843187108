import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  onlyLetters: string = '^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\\s]*)$';
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z-A-Z]{2,4}$';
  mobilePhonePattern = '^(\\+34|0034|34)?[67]\\d{8}$';
  phonePattern = '^(\\+34|0034|34)?[6789]\\d{8}$';
}
