import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { WelcomeComponent } from './private/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';
import { FormComponent } from './private/form/form.component';
import { RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmComponent } from './private/confirm/confirm.component';
import { ThanksComponent } from './private/thanks/thanks.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { Error401Component } from "./errors/error401/error401.component";
import { Error404Component } from './errors/error404/error404.component';
import { Error500Component } from "./errors/error500/error500.component";
import { CookiesPolicyComponent } from './legal/cookies-policy/cookies-policy.component';
import { LegalNoticeComponent } from './legal/legal-notice/legal-notice.component';
import { TermsConditionsComponent } from './help/terms-conditions/terms-conditions.component';
import { FaqsComponent } from './help/faqs/faqs.component';
import { PagesRoutingModule } from './pages-routing.module';
import { NgxCaptchaModule } from "ngx-captcha";
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';


@NgModule({
  declarations: [
    WelcomeComponent,
    FormComponent,
    ConfirmComponent,
    ThanksComponent,
    PrivacyPolicyComponent,
    Error401Component,
    Error404Component,
    Error500Component,
    CookiesPolicyComponent,
    LegalNoticeComponent,
    TermsConditionsComponent,
    FaqsComponent,
    LoginComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterLink,
    ReactiveFormsModule,
    PagesRoutingModule,
    NgOptimizedImage,
    NgxCaptchaModule,
    FormsModule
  ]
})
export class PagesModule { }
