import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loading = true;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    localStorage.removeItem('insuadvisor_data_sess');
    sessionStorage.removeItem('insuadvisor_token');
    this.getParamsFromURL();
  }

  private getParamsFromURL() {
    setTimeout(()=>{
      this.activatedRoute.params.subscribe(params => {
        const baseToken = params['token'];
        if (baseToken) {
          const token = baseToken;
          if (token) {
            try {
              this.apiService.checkToken(token)
                .subscribe(
                  response => {
                    this.authService.setToken(response.token);
                    this.router.navigate(['welcome']);
                  },
                  error => {
                    console.log(error)
                    if (error.includes('Unknown Error')) {
                      this.router.navigate(['server-error']);
                    } else {
                      this.router.navigate(['auth-error']);
                    }
                  });
            } catch (e) {
              this.router.navigate(['auth-error']);
            }
          } else {
            this.router.navigate(['auth-error']);
          }
        } else {
          this.router.navigate(['auth-error']);
        }
      });
    },3000);
  }

}
