<div class="mt-2 mb-4 animated fadeIn">
  <h4 class="mb-3">Términos y condiciones</h4>
  <div class="accordion mt-4" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          Term 1
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>Answer</p>
        </div>
      </div>
    </div>
  </div>
</div>
