<div class="step-template animated fadeIn">
  <form class="needs-validation" [formGroup]="userDataForm" novalidate>
    <div *ngIf="currentStep === 1" id="step-1" class="mt-5">
      <div class="form-steps-header">
        <div class="step-bubble">1</div>
        <h4>Datos personales</h4>
      </div>
      <div class="form-step card m-0 p-1 pb-4">
        <div class="row">
          <div class="col-md-4 mb-2">
            <div class="form-group">
              <label for="name" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Nombre &#x2a;</span>
              </label>
              <input class="form-control mt-2" type="text" id="name" formControlName="name" required
                     [ngClass]="{'is-valid': !form['name'].errors && form['name'].touched,'is-invalid': form['name'].errors && form['name'].touched}" placeholder="Nombre"/>
              <div *ngIf="isFieldValid('name', form['name'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('name', form['name']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('name', form['name']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="form-group">
              <label for="lastname1" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Primer apellido &#x2a;</span>
              </label>
              <input class="form-control mt-2" type="text" id="lastname1" formControlName="lastname1" required
                     [ngClass]="{'is-valid': !form['lastname1'].errors && form['lastname1'].touched,'is-invalid': form['lastname1'].errors && form['lastname1'].touched}" placeholder="Primer apellido"/>
              <div *ngIf="isFieldValid('lastname1', form['lastname1'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('lastname1', form['lastname1']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('lastname1', form['lastname1']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="form-group">
              <label for="lastname2" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Segundo apellido</span>
              </label>
              <input class="form-control mt-2" type="text" id="lastname2" formControlName="lastname2" [ngClass]="{'is-valid': !form['lastname2'].errors && form['lastname2'].touched,'is-invalid': form['lastname2'].errors && form['lastname2'].touched, 'not-required-empty': !form['lastname2'].errors && form['lastname2'].touched && form['lastname2'].value === ''}" placeholder="Segundo apellido"/>
              <div *ngIf="isFieldValid('lastname2', form['lastname2'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('lastname2', form['lastname2']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('lastname2', form['lastname2']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-2 mt-2">
              <small class="mandatory-fields">* Campos obligatorios</small>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-2 mt-2">
              <button class="btn btn-primary shadow-sm rounded" (click)="currentStep = currentStep + 1" [disabled]="form['name'].invalid || form['lastname1'].invalid || form['lastname2'].invalid">Siguiente <i class="right bi bi-chevron-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentStep === 2" id="step-2" class="mt-5">
      <div class="form-steps-header">
        <div class="step-bubble">2</div>
        <h4>Datos de contacto</h4>
      </div>
      <div class="form-step card m-0 p-1 pb-4">
        <div class="row">
          <div class="col-md-4 mb-2">
            <div class="form-group">
              <label for="email" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Email &#x2a;</span>
              </label>
              <input class="form-control mt-2" type="email" id="email" formControlName="email" required
                     [ngClass]="{'is-valid': !form['email'].errors && form['email'].touched,'is-invalid': form['email'].errors && form['email'].touched}" placeholder="Email"/>
              <div *ngIf="isFieldValid('email', form['email'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('email', form['email']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('email', form['email']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="form-group">
              <label for="phone1" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Teléfono principal (móvil) &#x2a;</span>
              </label>
              <input class="form-control mt-2" type="text" id="phone1" formControlName="phone1" minlength="9" maxlength="15" required
                     [ngClass]="{'is-valid': !form['phone1'].errors && form['phone1'].touched,'is-invalid': form['phone1'].errors && form['phone1'].touched}" placeholder="Teléfono principal"/>
              <div *ngIf="isFieldValid('phone1', form['phone1'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('phone1', form['phone1']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('phone1', form['phone1']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="form-group">
              <label for="phone2" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Otro teléfono</span>
              </label>
              <input class="form-control mt-2" type="text" id="phone2" formControlName="phone2" minlength="9" maxlength="15" [ngClass]="{'is-valid': !form['phone2'].errors && form['phone2'].touched,'is-invalid': form['phone2'].errors && form['phone2'].touched, 'not-required-empty': !form['phone2'].errors && form['phone2'].touched && form['phone2'].value === ''}" placeholder="Teléfono secundario"/>
              <div *ngIf="isFieldValid('phone2', form['phone2'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('phone2', form['phone2']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('phone2', form['phone2']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 mt-2">
            <small class="mandatory-fields">* Campos obligatorios</small>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 mt-2">
            <button class="btn btn-primary shadow-sm rounded" (click)="currentStep = currentStep - 1"><i class="left bi bi-chevron-left"></i> Anterior</button>
            <button class="btn btn-primary shadow-sm rounded" style="margin-left: 5px;" (click)="currentStep = currentStep + 1" [disabled]="form['email'].invalid || form['phone1'].invalid || form['phone2'].invalid">Siguiente <i class="right bi bi-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentStep === 3" id="step-3" class="mt-5">
      <div class="form-steps-header mt-4">
        <div class="step-bubble">3</div>
        <h4>Tipo de seguro</h4>
      </div>
      <div class="form-step card m-0 p-1 pb-4">
        <div class="row">
          <div class="col-xl-4 col-md-6 mb-2">
            <div *ngIf="!newCompany" class="form-group">
              <label for="company" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Anterior compañía *</span>
              </label>
              <select class="form-control mt-2" formControlName="currentCompany" id="company" [ngClass]="{'is-valid': !form['currentCompany'].errors && form['currentCompany'].touched,'is-invalid': form['currentCompany'].errors && form['currentCompany'].touched}" required>
                <option *ngFor="let broker of brokers" [ngValue]="broker.value">{{broker.name}}</option>
              </select>
            </div>
            <div *ngIf="newCompany" class="form-group">
              <label for="new-company" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Anterior compañía *</span>
              </label>
              <input class="form-control mt-2" type="text" id="new-company" formControlName="currentCompany" [ngClass]="{'is-valid': !form['currentCompany'].errors && form['currentCompany'].touched,'is-invalid': form['currentCompany'].errors && form['currentCompany'].touched, 'not-required-empty': !form['currentCompany'].errors && form['currentCompany'].touched && form['currentCompany'].value === ''}" placeholder="Anterior compañía" required/>
              <div *ngIf="isFieldValid('currentCompany', form['currentCompany'])" class="invalid-feedback">
                <small *ngIf="showFieldErrorFeedback('currentCompany', form['currentCompany']) === 'required'">
                  Campo obligatorio
                </small>
                <small *ngIf="showFieldErrorFeedback('currentCompany', form['currentCompany']) === 'pattern'">
                  Formato incorrecto
                </small>
              </div>
            </div>
            <div class="form-switch">
              <label class="switch">
                <input type="checkbox" [checked]="newCompany" (change)="newCompany = !newCompany">
                <span class="slider round"></span>&nbsp;
              </label>
            </div>
            <div class="switch-label">
              <label>&nbsp;¿No aparece en la lista?</label>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-2">
            <div class="form-group">
              <label for="coverage" class="ms-2 position-absolute">
                <span class="h6 small bg-white px-1">Tipo de seguro &#x2a;</span>
              </label>
              <select class="form-control mt-2" formControlName="coverage" id="coverage" [ngClass]="{'is-valid': !form['coverage'].errors && form['coverage'].touched,'is-invalid': form['coverage'].errors && form['coverage'].touched}" required>
                <option *ngFor="let insurance of insurances" [ngValue]="insurance.value">{{insurance.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-md-6 mb-2 mt-3">
            <div class="form-group">
              <label for="checkPrivacy" class="ms-2 position-absolute">
                <span class="h6 small px-1 label-acceptance">
                  He leído y acepto la <a href="javascript:void(0)" routerLink="/privacy-policy" target="_blank">política de privacidad</a> del sitio. *
                </span>
              </label>
              <input class="form-check-input" type="checkbox" value="" formControlName="checkPrivacy" id="checkPrivacy" required
                     [ngClass]="{'is-valid': !form['checkPrivacy'].errors && form['checkPrivacy'].touched,'is-invalid': form['checkPrivacy'].errors && form['checkPrivacy'].touched}">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-md-6 mb-2">
            <div class="form-group">
              <label for="checkCalling" class="ms-2 position-absolute">
                <span class="h6 small px-1 label-acceptance">
                  Acepto  recibir la llamada de un agente comercial con el fin de finalizar este proceso. *
                </span>
              </label>
              <input class="form-check-input" type="checkbox" value="" formControlName="checkCalling" id="checkCalling" required
                     [ngClass]="{'is-valid': !form['checkCalling'].errors && form['checkCalling'].touched,'is-invalid': form['checkCalling'].errors && form['checkCalling'].touched}">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 mt-3">
            <small class="mandatory-fields">* Campos obligatorios</small>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3 mt-2">
            <ngx-invisible-recaptcha #recaptcha
                                     [siteKey]="siteKey"
                                     (reset)="handleReset()"
                                     (ready)="handleReady()"
                                     (load)="handleLoad()"
                                     (success)="handleSuccess($event)"
                                     [useGlobalDomain]="false"
                                     [type]="type"
                                     [theme]="theme"
                                     [badge]="badge"
                                     [ngModel]="recaptcha"
                                     [ngModelOptions]="{ standalone: true }">
            </ngx-invisible-recaptcha>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 mt-2">
            <button class="btn btn-primary shadow-sm rounded" (click)="currentStep = currentStep - 1"><i class="left bi bi-chevron-left"></i> Anterior</button>
            <button class="btn btn-primary shadow-sm rounded" style="margin-left: 5px;" (click)="saveFormData()" [disabled]="form['coverage'].invalid || form['checkPrivacy'].invalid || form['checkCalling'].invalid || form['currentCompany'].invalid">Siguiente <i class="right bi bi-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
