export class UserDataModel {

    public name: string;
    public lastname1: string;
    public lastname2: string;
    public email: string;
    public phone1: string;
    public phone2: string;
    public currentCompany: string;
    public coverage: string;

  constructor(obj: {
    coverage: string | '';
    currentCompany: string | '';
    name: string | '';
    phone2: string | '';
    lastname2: string | '';
    lastname1: string | '';
    email: string | '';
    phone1: string | ''
  }) {
        this.name = obj && obj.name || '';
        this.lastname1 = obj && obj.lastname1 || '';
        this.lastname2 = obj && obj.lastname2 || '';
        this.email = obj && obj.email || '';
        this.phone1 = obj && obj.phone1 || '';
        this.phone2 = obj && obj.phone2 || '';
        this.currentCompany = obj && obj.currentCompany || '';
        this.coverage = obj && obj.coverage || '';
    }

}

interface DataObj {
    name: string;
    lastname1: string;
    lastname2: string;
    email: string;
    phone1: string;
    phone2: string;
    currentCompany: string;
    coverage: string;
}
