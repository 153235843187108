<footer class="footer mt-auto py-3 pt-5 pb-4">
  <div class="container">
    <div class="row">
        <div class="row d-flex justify-content-left">
            <div class="col-md-4">
                <img src="assets/images/logo-insuadvisor.png" alt="Logo"/>
                <ul class="list-unstyled small mt-5">
                    <li class="mb-2" style="font-weight: 300;">
                        <div class="d-flex justify-content-left">
                            <i class="bi bi-whatsapp header-icon"></i>
                            <div>
                                <a href="https://api.whatsapp.com/send?phone=34672694521" target="_blank"><span class="header-link">¿Tienes alguna duda?</span><p class="info">Te atenderemos por <strong>WhatsApp<br>(+34) 672 694 521</strong></p></a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-3">
                        <h5>Sobre nosotros</h5>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="https://insuadvisor.com/sobre-insuadvisor/" target="_blank">Sobre Insuadvisor</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/siniestros/" target="_blank">Siniestros</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/colaboremos/" target="_blank">Colaboremos</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/contacto/" target="_blank">Contacto</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/trabaja-con-nosotros/" target="_blank">Trabaja con nosotros</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h5>Seguros Particulares</h5>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-particulares/seguros-auto/" target="_blank">Seguros Auto</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-particulares/seguro-hogar/" target="_blank">Seguro Hogar</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-particulares/seguro-de-comunidades/" target="_blank">Seguro de comunidades</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-particulares/seguro-de-comercio/" target="_blank">Seguro de comercio</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-particulares/seguro-de-salud/" target="_blank">Seguro de Salud</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-particulares/seguro-de-vida/" target="_blank">Seguro de Vida</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h5>Seguros Empresas</h5>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="https://insuadvisor.com/automocion/" target="_blank">Especial Automoción</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-empresas/seguro-de-flotas-vo-y-vn/" target="_blank">Seguro de flotas VO y VN</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-empresas/seguro-de-danos/" target="_blank">Seguro de daños</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-empresas/seguro-de-responsabilidad-civil/" target="_blank">Seguro de Responsabilidad Civil</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-empresas/colectivos-salud/" target="_blank">Colectivos Salud</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-empresas/colectivos-vida/" target="_blank">Colectivos Vida</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/seguros-empresas/convenio/" target="_blank">Convenio</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h5>Términos Legales</h5>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="https://insuadvisor.com/aviso-legal/" target="_blank">Aviso legal</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/politica-privacidad/" target="_blank">Política de privacidad</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/politica-de-cookies/" target="_blank">Política de cookies</a></li>
                            <li class="mb-2"><a href="https://insuadvisor.com/faqs/" target="_blank">Faqs</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="copyright col-12 col-lg-4">
        <ul class="list-unstyled small mt-4">
          <li class="mb-2"><span class="text-muted">&copy; {{currentYear}} Todos los derechos reservados</span></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
