<div class="mt-2 mb-4 animated fadeIn">
  <h4 class="mb-3">Política de privacidad</h4>
  <p>La presente política de privacidad se aplica al tratamiento de los datos personales de
    los clientes y/o usuarios del sitio web (<a href="https://insuadvisor.com/">InsuAdvisor</a>) cuyo Responsable del
    tratamiento de datos es ATL FOREVER S.L.</p>
  <div class="accordion mt-4" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          ¿Quién es el titular de esta web?
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p><strong>Identidad:</strong> ATL FOREVER CIF: B44603371</p>
          <p><strong>Datos registrales:</strong>  Inscrito en el Registro Mercantil de Córdoba, Tomo 1.420, Folio 118, Hoja CO-14.854 e Inscripción 1ª</p>
          <p><strong>Dirección postal:</strong>  Parque Joyero de Córdoba. Carretera Palma del Río A-431, KM 3,3- 14005-Córdoba</p>
          <p><strong>Teléfono:</strong> 957498526 (Ext-correduría de seguros)</p>
          <p><strong>Correo electrónico:</strong> <a href="mailto:info@insuadvisor.com">info@insuadvisor.com</a></p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOne">
          ¿Quién es el responsable del tratamiento de sus datos de carácter personal?
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p><strong>Identidad:</strong> ATL FOREVER CIF: B44603371</p>
          <p><strong>Datos registrales:</strong> Inscrito en el Registro Mercantil de Córdoba, Tomo 1.420, Folio 118, Hoja CO-14.854 e Inscripción 1ª</p>
          <p><strong>Dirección postal:</strong> Parque Joyero de Córdoba. Carretera Palma del Río A-431, KM 3,3- 14005-Córdoba</p>
          <p><strong>Teléfono:</strong> 957498526 (Ext-correduría de seguros)</p>
          <p><strong>Correo electrónico:</strong> <a href="mailto:info@insuadvisor.com">info@insuadvisor.com</a></p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
          ¿Con qué finalidad trataremos sus datos de carácter personal y durante cuánto tiempo los conservaremos?
        </button>
      </h2>
      <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>La empresa tratará sus datos de carácter personal exclusivamente para fines
            determinados, explícitos y legítimos, y no serán tratados de manera
            incompatible con dichos fines.</p>
          <p><strong>Finalidad 1 –</strong> Para todos los usuarios: Gestión, estudio y resolución de consultas efectuadas a través de la web.</p>
          <p><strong>Finalidad 2 -</strong> Para los usuarios que lo acepten expresamente: Envíos de comunicaciones comerciales de productos y servicios de la empresa.</p>
          <p><strong>Finalidad 3 –</strong> Para los clientes de la tienda on-line o que contraten alguno de nuestros servicios: Gestión contable, fiscal y administrativa.</p>
          <p><strong>Finalidad 4 –</strong> Para los usuarios que nos envíen su CV: Gestión de procesos de selección de personal.</p>
          <p>La empresa conservará sus datos de carácter personal el tiempo necesario según la información facilitada y
            los plazos de conservación recogidos en normativas que sean aplicables.</p>
          <p>La empresa tratará los datos personales facilitados por los usuarios a través de los formularios electrónicos
            existentes en el sitio web <a href="https://insuadvisor.com/">InsuAdvisor</a> para los fines que se identifican a continuación:</p>
          <ol>
            <li>
              <p>
                <strong>En relación con el “Formulario contacto”:</strong>
              </p>
              <p>
                <strong>Finalidad:</strong> Gestión, estudio y resolución de las consultas efectuadas, así como
                enviar comunicaciones comerciales sobre nuestros servicios, siempre y cuando
                el interesado haya consentido el tratamiento de sus datos personales para esta
                finalidad.
              </p>
              <p>
                <strong>Legitimación:</strong> El consentimiento del interesado, que podrá revocar en cualquier momento.
              </p>
            </li>
            <li>
              <p>
                <strong>En relación con el “Formulario Blog”:</strong>
              </p>
              <p><strong>Finalidad:</strong> Gestión de la publicación de comentarios efectuados en nuestro Blog
                a través de nuestra página web, así como enviar comunicaciones comerciales sobre nuestros servicios,
                siempre y cuando el interesado haya consentido el
                tratamiento de sus datos personales para esta finalidad.</p>
              <p><strong>Legitimación:</strong> El consentimiento del interesado, que podrá revocar en cualquier
                momento.</p>
            </li>
            <li>
              <p><strong>En relación con el “Formulario precio y coberturas”:</strong></p>
              <p><strong>Finalidad:</strong> Gestión, estudio y resolución de las consultas y comparaciones
                solicitadas, así como enviar comunicaciones comerciales sobre nuestros
                servicios, siempre y cuando el interesado haya consentido el tratamiento de
                sus datos personales para esta finalidad.</p>
              <p><strong>Legitimación:</strong> El consentimiento del interesado, que podrá revocar en cualquier
                momento.</p>
            </li>
            <li>
              <p><strong>En relación con el “Formulario contratación”:</strong></p>
              <p><strong>Finalidad:</strong> Gestión, estudio y resolución de las peticiones de contratación de
                seguros a través de nuestra página web, así como enviar comunicaciones
                comerciales sobre nuestros servicios, siempre y cuando el interesado haya
                consentido el tratamiento de sus datos personales para esta finalidad.</p>
              <p><strong>Legitimación:</strong> El consentimiento del interesado, que podrá revocar en cualquier
                momento.</p>
            </li>
          </ol>
          <p>Cuando los datos solicitados en los formularios electrónicos sean necesarios
            para acceder a funcionalidades concretas del sitio web, ATL FOREVER S.L.
            indicará dicho carácter obligatorio en el momento de la recogida de datos del
            Usuario y no facilitarlos implicará que no pueda atenderse la solicitud
            correspondiente.</p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
          ¿A qué destinatarios se comunicarán sus datos?
        </button>
      </h2>
      <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>Los datos se comunicarán a las siguientes empresas y con las siguientes finalidades:</p>
          <p><strong>Destinatario:</strong> ATL FOREVER S.L. CIF: B44603371</p>
          <p><strong>Finalidad:</strong> Empresa encargada del mantenimiento y programación de nuestra página web.</p>
          <p><strong>Legitimación:</strong> Ejecución de un contrato de prestación de servicios.</p>
          <p>No está prevista la realización de transferencias de datos a terceros países.</p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
          ¿Cuáles son sus derechos cuando nos facilita sus datos?
        </button>
      </h2>
      <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>Cualquier persona tiene derecho a obtener confirmación sobre si en la empresa estamos tratando datos personales que les conciernan, o no.
            En concreto, puede usted dirigirse a la empresa a su dirección postal o bien a la dirección de email facilitada en el presente documento con la finalidad de ejercer los
            siguientes derechos:</p>
          <ul>
            <li>Derecho a solicitar el acceso a los datos personales relativos al interesado.</li>
            <li>Derecho a solicitar su rectificación o supresión.</li>
            <li>Derecho a solicitar la limitación de su tratamiento.</li>
            <li>Derecho a oponerse al tratamiento.</li>
            <li>Derecho a la portabilidad de los datos.</li>
          </ul>
          <p>Puede usted solicitar los formularios necesarios para el ejercicio de estos derechos
            enviando un correo electrónico a la dirección de email facilitada en el presente
            documento. Dado el carácter personalísimo del ejercicio de cualquiera de estos
            derechos usted deberá adjuntar a la solicitud copia de su carnet de identidad o
            documento acreditativo equivalente. Puede usted dirigirse a la Agencia Española de protección de Datos para
            obtener información adicional acerca de sus derechos o
            presentar una reclamación ante la misma, especialmente cuando no haya obtenido
            satisfacción en el ejercicio de sus derechos, obteniendo toda la información necesaria
            para ello a través de la web <a href="www.agpd.es">www.agpd.es</a></p>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseFive">
          ¿Qué medidas de seguridad se aplican?
        </button>
      </h2>
      <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <p>InsuAdvisor nombre comercial de la empresa ATL FOREVER S.L adopta los niveles de
            seguridad requeridos por el RGPD adecuados a la naturaleza de los datos que son
            objeto de tratamiento en cada momento. No obstante, lo anterior, el Usuario debe
            tener en cuenta que la seguridad de los sistemas no es inexpugnable y pueden existir
            actuaciones dolosas de terceros, si bien ATL FOREVER S.L pone todos los medios a su
            alcance para evitar dichas actuaciones. Puede solicitar mayor información acerca de
            nuestras medidas de seguridad al email info@insuadvisor.com</p>
        </div>
      </div>
    </div>
  </div>
</div>
