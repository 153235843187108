import * as fromUserData from './user-data.actions';
import { UserDataModel } from './user-data.model';

export interface UserDataState {
    userData: UserDataModel
}

const initState: UserDataState = {
  // @ts-ignore
  userData: null
};

export function userDataReducer(state = initState, action: fromUserData.actions): UserDataState {

    switch (action.type) {

        case fromUserData.SET_USER_DATA:
            return {
                userData: {... action.userData}
            };

        case fromUserData.UNSET_USER_DATA:
            return {
              // @ts-ignore
                userData: null
            };

        default:
            return state;
    }

}
