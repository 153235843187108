import { Action } from '@ngrx/store';
import { UserDataModel } from './user-data.model';

export const SET_USER_DATA = '[USER_DATA] Set USER_DATA';
export const UNSET_USER_DATA = '[USER_DATA] Unset USER_DATA';

export class SetUserDataAction implements Action {
    readonly  type = SET_USER_DATA;

    constructor (public userData: UserDataModel) {}
}

export class UnSetUserDataAction implements Action {
    readonly  type = UNSET_USER_DATA;
}

export type actions = SetUserDataAction | UnSetUserDataAction;
