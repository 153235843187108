import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { AppState } from "../../../../app.reducer";
import { Store } from "@ngrx/store";
import { UserDataService } from "../../../../services/user-data.service";
import { UserDataModel } from "../../../../store/user-data.model";
import { filter, Subscription } from "rxjs";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  public userData: any = {};
  public loading: boolean = false;
  // @ts-ignore
  private userDataObject: UserDataModel;
  private subscription: Subscription = new Subscription();

  constructor(private router: Router,
              private apiService: ApiService,
              private store: Store<AppState>,
              private userDataService: UserDataService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('insuadvisor_token') === null || sessionStorage.getItem('insuadvisor_token') === 'undefined') {
      this.router.navigate(['login']);
    } else {
      this.userDataService.loadData();
      this.subscription = this.store.select('user_data_state')
        .pipe(filter(state => state.userData != null))
        .subscribe(user_data_state => {
          this.userDataObject =  new UserDataModel({
            name: user_data_state.userData.name,
            lastname1: user_data_state.userData.lastname1,
            lastname2: user_data_state.userData.lastname2,
            email: user_data_state.userData.email,
            phone1: user_data_state.userData.phone1,
            phone2: user_data_state.userData.phone2,
            currentCompany: user_data_state.userData.currentCompany,
            coverage: user_data_state.userData.coverage
          });
          this.userData = this.userDataObject;
        });
    }
  }

  public sendData() {
    this.loading = true;
    setTimeout(() => {
      this.apiService.storeFormData(this.userData)
          .subscribe(
              response => {
                this.loading = false;
                this.router.navigate(['/thanks']);
              },
              error => {
                this.loading = false;
              });
    }, 3000);
  }

}
