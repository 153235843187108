import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { UserDataModel } from 'src/app/store/user-data.model';
import { SetUserDataAction } from 'src/app/store/user-data.actions';
import { appConfig } from 'src/app/config/config';

@Injectable({
    providedIn: 'root'
})
export class UserDataService {

    private readonly nameSess: string;
    // @ts-ignore
    private user_data: UserDataModel;

    constructor(private store: Store<AppState>) {
        this.nameSess = appConfig.data_session;
        this.loadData();
    }

    loadData() {
        if (localStorage.getItem(this.nameSess)) {
            // @ts-ignore
          this.user_data = JSON.parse(atob(localStorage.getItem(this.nameSess)));
        } else {
            this.user_data = new UserDataModel({
              name: '',
              lastname1: '',
              lastname2: '',
              email: '',
              phone1: '',
              phone2: '',
              currentCompany: '',
              coverage: ''
            });
        }
        this.store.dispatch(new SetUserDataAction(this.user_data));
        this.persistData(this.user_data);
    }

    persistData(user_data: UserDataModel) {
        this.user_data = user_data;
        let data_sess = btoa(JSON.stringify(this.user_data));
        localStorage.setItem(this.nameSess, data_sess);
    }
}
