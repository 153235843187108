import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  setToken(token: string) {
    sessionStorage.setItem('insuadvisor_token', token);
  }

  getToken() {
    return sessionStorage.getItem('insuadvisor_token');
  }

}
