import { ActionReducerMap } from '@ngrx/store';

import * as fromUserData from './store/user-data.reducer';

export interface AppState {
    user_data_state: fromUserData.UserDataState;
}

export const appReducers: ActionReducerMap<AppState> = {
  // @ts-ignore
    user_data_state: fromUserData.userDataReducer,
};
