<div *ngIf="loading" id="overlay">
  <div class="mt-5 mb-5">
    <div class="text-center">
      <div class="lds-dual-ring"></div>
    </div>
  </div>
</div>
<div class="animated fadeIn">
  <h3 class="mb-3 mt-4">¡Ya casi está!</h3>
  <p class="mt-2">Confirma que la información es correcta para obtener tu oferta. Si hay algún error, o necesitas modificar algún dato, <a href="javascript:void(0)" routerLink="/form" class="start-over">haz click aquí</a> para comenzar de nuevo.</p>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-group">
        <li class="list-group-item list-group-item-header">Cobertura: {{userData['coverage']}}</li>
        <li class="list-group-item">
          Nombre: {{userData['name']}}
          <span> {{userData['lastname1']}}</span>
          <span *ngIf="userData['lastname2'] !== '' && userData['lastname2'] !== null"> {{userData['lastname2']}}</span>
        </li>
        <li class="list-group-item">Email: {{userData['email']}}</li>
        <li class="list-group-item">Teléfono principal: {{userData['phone1']}}</li>
        <li *ngIf="userData['phone2'] !== '' && userData['phone2'] !== null" class="list-group-item">Teléfono secundario: {{userData['phone2']}}</li>
        <li *ngIf="userData['currentCompany'] !== '' && userData['currentCompany'] !== null" class="list-group-item">Compañía anterior: {{userData['currentCompany']}}</li>
      </ul>
      <button class="mt-4 btn btn-primary shadow-sm rounded" (click)="sendData()">
        Obtener oferta <i class="right bi bi-car-front"></i>
      </button>
    </div>
  </div>
</div>
