<div class="mt-5 animated fadeIn text-center">
    <div class="d-flex justify-content-center text-center mt-lg-5">
        <div class="bg-gradient-primary-to-secondary">
            <div class="featured-img-container">
                <div class="featured-img mb-2 mt-2">
                    <div class="stage">
                        <div class="dot-flashing"></div>
                    </div>
                </div>
                <h4><strong>Redirigiendo al sitio corporativo...</strong></h4>
            </div>
        </div>
    </div>
</div>


