import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  checkToken(token: string) {
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(`${environment.URL_API}/insurance/check/token`, {}, {headers: headers});
  }

  storeFormData(data: any) {
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.authService.getToken()}`);
    return this.http.post<any>(`${environment.URL_API}/insurance/store/form`, data, {headers: headers});
  }

}
