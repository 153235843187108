import { BrokerModel } from './broker.model';

export const brokers: BrokerModel[] = [
  { name: 'Seleccionar', value: '' },
  { name: 'Allianz', value: 'Allianz' },
  { name: 'AXA', value: 'AXA' },
  { name: 'Caser', value: 'Caser' },
  { name: 'Catalana Occidente', value: 'Catalana Occidente' },
  { name: 'Direct Seguros', value: 'Direct Seguros' },
  { name: 'Fénix Directo', value: 'Fénix Directo' },
  { name: 'Generali', value: 'Generali' },
  { name: 'Línea Directa', value: 'Línea Directa' },
  { name: 'Mapfre', value: 'Mapfre' },
  { name: 'Mutua Madrileña', value: 'Mutua Madrileña' },
  { name: 'Pelayo', value: 'Pelayo' },
  { name: 'Qualitas Auto', value: 'Qualitas Auto' },
  { name: 'RACC', value: 'RACC' },
  { name: 'Race', value: 'Race' },
  { name: 'Reale', value: 'Reale' },
  { name: 'Verti', value: 'Verti' },
  { name: 'Verti', value: 'Verti' },
  { name: 'Sin Seguro Anterior', value: 'Sin Seguro Anterior' }
]
