import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmComponent } from './private/confirm/confirm.component';
import { Error401Component } from "./errors/error401/error401.component";
import { Error404Component } from './errors/error404/error404.component';
import { Error500Component } from "./errors/error500/error500.component";
import { CookiesPolicyComponent } from './legal/cookies-policy/cookies-policy.component';
import { FaqsComponent } from './help/faqs/faqs.component';
import { FormComponent } from './private/form/form.component';
import { LegalNoticeComponent } from './legal/legal-notice/legal-notice.component';
import { ThanksComponent } from './private/thanks/thanks.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './help/terms-conditions/terms-conditions.component';
import { WelcomeComponent } from './private/welcome/welcome.component';
import { LoginComponent } from "./login/login.component";
import {
  HomeComponent
} from "./home/home.component";

const routes: Routes = [
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'auth-error', component: Error401Component
  },
  {
    path: 'not-found', component: Error404Component
  },
  {
    path: 'server-error', component: Error500Component
  },
  {
    path: 'cookies-policy', component: CookiesPolicyComponent
  },
  {
    path: 'legal-notice', component: LegalNoticeComponent
  },
  {
    path: 'privacy-policy', component: PrivacyPolicyComponent
  },
  {
    path: 'terms-conditions', component: TermsConditionsComponent
  },
  {
    path: 'faqs', component: FaqsComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'login/:token', component: LoginComponent
  },
  {
    path: 'welcome', component: WelcomeComponent
  },
  {
    path: 'form', component: FormComponent
  },
  {
    path: 'confirm', component: ConfirmComponent
  },
  {
    path: 'thanks', component: ThanksComponent
  },
  {
    path: '', pathMatch: 'full', redirectTo: 'home'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {

}
