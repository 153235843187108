<nav class="container-fluid py-1 navbar navbar-expand-lg">
  <div class="container">
    <a class="navbar-brand" routerLink="/home">
      <img src="assets/images/logo-insuadvisor.png" alt="Logo"/>
    </a>
<!--    <div class="d-block d-sm-none">-->
<!--      <small class="xs">Empresa del <a href="https://gesthispania.com" target="_blank"><img ngSrc="../../../../assets/images/logo-grupo-gesthispania.svg" alt="Logo Grupo Gesthispania" height="14" width="auto" class="link-grupo"/></a></small>-->
<!--    </div>-->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
        <ul class="list-unstyled list-inline mt-3 mb-0">
            <li class="list-inline-item">
                <div class="d-flex justify-content-center">
                    <i class="bi bi-whatsapp header-icon"></i>
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=34672694521" target="_blank"><span class="header-link">Atención 24/7</span><p class="info">WhatsApp</p></a>
                    </div>
                </div>
            </li>
            <li class="list-inline-item" style="margin-left: 2em;">
                <div class="d-flex justify-content-center">
                    <i class="bi bi-file-earmark-text header-icon"></i>
                    <div>
                        <a href="https://insuadvisor.com/siniestros/" target="_blank"><span class="header-link">Siniestros</span><p class="info">Información</p></a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
  </div>
</nav>
