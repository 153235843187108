import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('insuadvisor_token') === null || sessionStorage.getItem('insuadvisor_token') === 'undefined') {
      this.router.navigate(['login']);
    }
  }

}
